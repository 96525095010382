import React, { FunctionComponent, useEffect, useRef } from 'react';

const Survey: FunctionComponent = () => {
	const typeFormContainer = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		if (!typeFormContainer.current) return;
		const script = document.createElement('script');
		script.src = '//embed.typeform.com/next/embed.js';
		script.async = true;
		typeFormContainer.current.appendChild(script);

		return () => {
			if (typeFormContainer.current) {
				const scriptElement = typeFormContainer.current.querySelector('script');
				if (scriptElement) typeFormContainer.current.removeChild(scriptElement);
			}
		};
	}, []);

	return (
		<div id='typeform-container' ref={typeFormContainer} style={{ position: 'relative', width: '100%', minHeight: '80vh' }}>
			<div data-tf-live='01JPQ4BG3EXCCM93D0WEP6D7YW'></div>
		</div>
	);
};

export default Survey;

import React from 'react';
import './custom-pill.styles.scss';
import i18next from 'i18next';

const CustomPill: React.FC = () => {
	return (
		<div className='custom-pill'>
			<span className='custom-pill-label'>{i18next.t('new')}</span>
		</div>
	);
};

export default CustomPill;
